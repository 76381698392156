import { graphql } from "gatsby";
import React from "react";
import Section from "../components/common/Section";
import SectionHeading from "../components/common/SectionHeading";
import Layout from "../layouts";

const Cookies = () => {
  return (
    <Layout title="general.cookiePolicy">
      <Section className="pt-20">
        <div className="flex flex-col gap-4 lg:flex-row lg:gap-16 mt-10">
          <div className="w-full">
            <SectionHeading as="h2">
              Polityka cookies serwisu connectedlife.pl
            </SectionHeading>
            <p className="my-4 text-left lg:text-justify text-text-primary dark:text-text-primary-dark leading-7">
              <ol className="list-decimal mx-4">
                <li>
                  Na stronach internetowych związanych z funkcjonowaniem Serwisu
                  wykorzystywana jest technika plików „cookies”.
                </li>
                <li>
                  Pliki „cookies” to dane informatyczne, w szczególności pliki
                  tekstowe, przechowywane w urządzeniach Użytkowników i
                  przeznaczone do korzystania ze stron internetowych. Pliki te
                  pozwalają rozpoznać urządzenie użytkownika i odpowiednio
                  wyświetlić stronę internetową dostosowaną do jego
                  indywidualnych preferencji. Domyślne parametry ciasteczek
                  pozwalają na odczytanie informacji w nich zawartych jedynie
                  serwerowi, który je utworzył. „Cookies” zazwyczaj zawierają
                  nazwę strony internetowej z której pochodzą, czas
                  przechowywania ich na urządzeniu końcowym oraz unikalny numer.
                </li>
                <li>
                  Pliki „cookies” używane są w celu dostosowania zawartości
                  stron internetowych do preferencji użytkownika oraz
                  optymalizacji korzystania ze stron internetowych. Pliki
                  „cookies” wykorzystywane są z wyłączeniem personalnej
                  identyfikacji Użytkownika. Usługodawca przetwarza dane zawarte
                  w plikach „cookies” podczas korzystania przez odwiedzających
                  ze strony internetowej Serwisu. Pliki „cookies” będą
                  przechowywane przede wszystkim w celu dostosowania strony do
                  indywidualnych potrzeb odwiedzających, tworząc statystyki
                  odwiedzin i przechowując dane Użytkownika (po zalogowaniu),
                  dzięki czemu Użytkownik nie musi każdorazowo wpisywać loginu i
                  hasła na każdej podstronie strony głównej; zmiany tych
                  ustawień mogą być dokonane w dowolnym momencie.
                </li>
                <li>
                  Rodzaje plików cookies
                  <ul className="p-1 list-disc">
                    <li>
                      'Tymczasowe' pliki cookies związane z sesją pozostają w
                      przeglądarce tylko przez czas sesji, tzn. do momentu
                      wyjścia z danej witryny.
                    </li>
                    <li>
                      'Trwałe' pliki cookies pozostają w przeglądarce po
                      zakończeniu sesji (o ile nie zostaną usunięte przez
                      użytkownika)
                    </li>
                    <li>
                      'Analityczne' pliki cookies zbierają informacje o
                      korzystaniu z danej witryny, takie jak strony odwiedzone
                      przez danego użytkownika oraz wszelkie komunikaty o
                      błędach; nie zbierają informacji umożliwiających
                      identyfikację użytkownika, a zebrane dane są agregowane w
                      taki sposób, że stają się anonimowe. Analityczne pliki
                      cookies służą do ulepszania działania witryny.
                    </li>
                    <li>
                      'Funkcjonalne' pliki cookies umożliwiają witrynie
                      zapamiętanie wszelkich wyborów dokonywanych na stronach
                      (takich jak zmiana rozmiaru czcionki, dostosowanie strony)
                      i umożliwiają takie usługi, jak dodawanie komentarzy na
                      czyimś blogu.
                    </li>
                  </ul>
                </li>
                <li>
                  Standardowo oprogramowanie służące do przeglądania stron
                  internetowych domyślnie dopuszcza umieszczanie plików
                  „cookies” na urządzeniu końcowym. Ustawienia te mogą zostać
                  zmienione w taki sposób, aby blokować automatyczną obsługę
                  plików „cookies” w ustawieniach przeglądarki internetowej bądź
                  informować o ich każdorazowym przesłaniu na urządzenie
                  użytkownika. Szczegółowe informacje o możliwości i sposobach
                  obsługi plików „cookies” dostępne są w ustawieniach
                  oprogramowania (przeglądarki internetowej). Ograniczenie
                  stosowania plików „cookies”, może wpłynąć na niektóre
                  funkcjonalności dostępne na stronie internetowej (przykładowo
                  niemożliwym może okazać się przejście ścieżki zamówienia).
                </li>
                <li>
                  Serwis korzysta z usługi Google Analytics, w celu śledzenia i
                  analizy ruchu w Serwisie, zbierania i analizy danych
                  dotyczących zachowania osób odwiedzających Serwis. Analiza
                  ruchu w sieci wykorzystywana jest głównie do optymalizacji
                  strony internetowej. Zgodnie z określonymi funkcjonalnościami
                  Google Analytics piki „cookies” mogą być wykorzystywane w
                  celach marketingowych, a dane w nich zawarte podlegać
                  profilowaniu.
                </li>
                <li>
                  Operatorem Google Analytics jest spółka Google Inc., 1600
                  Amphitheatre Pkwy, Mountain View, CA 94043-1351 z siedzibą w
                  USA.
                </li>
                <li>
                  W sytuacji wniesienia sprzeciwu wobec profilowania lub działań
                  marketingowych, prosimy skorzystać z odpowiednich ustawień
                  Państwa przeglądarki.
                </li>
                <li>
                  Użytkownik może w każdym czasie nie wyrazić zgody na
                  gromadzenie i przetwarzanie danych związanych z korzystaniem z
                  Serwisu przez Google Analytics, oraz na przetwarzanie tych
                  danych w związku z tym przez firmę Google, a także może w
                  każdym czasie uniemożliwić takie działania. W tym celu należy
                  pobrać dodatek do przeglądarki dostępny tutaj:
                  tools.google.com/dlpage/gaoptout, a następnie zainstalować go.
                </li>
                <li>
                  Serwis również korzysta lub może korzystać dla celów
                  marketingowych z następujących narzędzi wykorzystujących pliki
                  cookies: Google Tag Manager, Google Ads, Facebook Pixel,
                  Yandex Metrica. Wykorzystywanie plików cookies w związku z
                  korzystaniem z tych narzędzi następuje zgodnie z niniejszą
                  Polityką Cookies oraz zasadami udostępnianymi przez operatorów
                  tych narzędzi.
                </li>
                <li>
                  Poniższa tabela przedstawia pliki „cookies” wykorzystywane
                  przez spółkę ConnectedLife Sp. z o.o.
                  <table className="border border-collapse">
                    <thead>
                      <tr className="border">
                        <th className="border">Cookie</th>
                        <th className="border">Opis</th>
                        <th className="border">Okres ważności</th>
                        <th className="border">Przetwarzanie</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border">
                        <td className="border">_ga</td>
                        <td className="border">
                          Ten plik cookie jest instalowany przez Google
                          Analytics. Służy do obliczania danych na temat
                          odwiedzających, sesji oraz kampanii na potrzeby
                          raportów analitycznych. Pliki przechowują informacje
                          anonimowo i przypisują losowo wygenerowany numer w
                          celu identyfikacji unikalnych użytkowników.
                        </td>
                        <td className="border">2 lata</td>
                        <td className="border">Analityka</td>
                      </tr>
                      <tr className="border">
                        <td className="border">_gid</td>
                        <td className="border">
                          Ten plik cookie jest instalowany przez Google
                          Analytics. Plik cookie służy do przechowywania
                          informacji o tym, jak użytkownicy korzystają ze strony
                          internetowej i pomaga w tworzeniu raportu
                          analitycznego. Dane, w tym liczba odwiedzających,
                          źródło, z którego pochodzą, oraz odwiedzane strony są
                          anonimowe.
                        </td>
                        <td className="border">1 dzień</td>
                        <td className="border">Analityka</td>
                      </tr>
                      <tr className="border">
                        <td className="border">_gat</td>
                        <td className="border">
                          Te pliki cookie są instalowane przez Google Universal
                          Analytics w celu ograniczenia liczby żądań i
                          ograniczenia gromadzenia danych w witrynach o dużym
                          ruchu
                        </td>
                        <td className="border">3 miesiące</td>
                        <td className="border">Analityka</td>
                      </tr>
                      <tr className="border">
                        <td className="border">_gcl_au</td>
                        <td className="border">
                          Ten plik cookie jest wykorzystywany przez Google
                          Analytics do zbierania interakcji użytkownika ze
                          stroną internetową.
                        </td>
                        <td className="border">3 miesiące</td>
                        <td className="border">Analityka</td>
                      </tr>
                      <tr className="border">
                        <td className="border">Test_cookie</td>
                        <td className="border">
                          Ten plik cookie jest ustawiany przez doubleclick.net.
                          Celem pliku cookie jest ustalenie, czy przeglądarka
                          użytkownika obsługuje pliki cookie.
                        </td>
                        <td className="border">15 minut</td>
                        <td className="border">Reklamy</td>
                      </tr>
                      <tr className="border">
                        <td className="border">_fbp</td>
                        <td className="border">
                          Ten plik cookie jest ustawiany przez Facebooka w celu
                          wyświetlania reklam, które są wyświetlane na Facebooku
                          lub platformie cyfrowej obsługiwanej przez reklamy
                          Facebooka po odwiedzeniu tej witryny.
                        </td>
                        <td className="border">3 miesiące</td>
                        <td className="border">Reklamy</td>
                      </tr>
                      <tr className="border">
                        <td className="border">fr</td>
                        <td className="border">
                          Używany przez Facebooka w celu wyświetlania reklam.
                        </td>
                        <td className="border">3 miesiące</td>
                        <td className="border">Reklamy</td>
                      </tr>
                    </tbody>
                  </table>
                  Poza ciasteczkami wymieniony w powyższej tabeli należy wziąć
                  pod uwagę obecność plików sesyjnych (ciasteczka sesji), które
                  są tymczasowo przechowywane na komputerze użytkownika i
                  automatycznie usuwane po zamknięciu przeglądarki internetowej.
                  Ich używanie jest ograniczone wyłącznie do transmisji danych
                  identyfikacyjnych sesji (reprezentowanych przez przypadkowe
                  cyfry generowane przez serwer), które są konieczne w celu
                  umożliwienia wykonania konkretnej usługi oraz w celu
                  bezpiecznej i wydajnej nawigacji na stronie. Tak zwane pliki
                  sesyjne używane na tej stronie nie odwołują się do innych
                  technologii informatycznych, potencjalnie szkodliwych dla
                  poufności danych Użytkownika. Nie pozwalają one na przejęcie
                  jego danych personalnych.
                </li>
                <li>
                  Ustawienia przeglądarki internetowej dotyczące plików
                  „cookies” wpływają na wyrażenie zgody na korzystanie z plików
                  „cookies” lub brak takiej zgody. Zgoda może być również
                  wyrażona, zmieniona lub odwołana poprzez odpowiednie
                  ustawienia przeglądarki internetowej. Ustawienia używanej
                  przeglądarki internetowej mogą również blokować, aby usługa
                  Google Analytics umieściła plik cookie w Państwa systemie.
                  Wykorzystywane przez usługę Google Analytics pliki „cookies”,
                  jak również inne pliki „cookies” można w dowolnej chwili
                  wyłączyć lub usunąć za pomocą przeglądarki internetowej.
                </li>
                <li>
                  Szczegółowe informacje na temat zmiany ustawień dotyczących
                  plików „cookies” oraz ich samodzielnego usuwania w
                  najpopularniejszych przeglądarkach internetowych dostępne są w
                  dziale pomocy przeglądarki internetowej:
                  <ul className="p-1 list-disc">
                    <li>
                      Mozilla Firefox:
                      https://support.mozilla.org/it/kb/Gestione%20dei%20cookie
                    </li>
                    <li>
                      Google Chrome:
                      https://support.google.com/chrome/answer/95647?hl=it
                    </li>
                    <li>
                      Internet Explorer:
                      http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies
                    </li>
                    <li>
                      Safari 6/7 Mavericks:
                      https://support.apple.com/kb/PH17191?viewlocale=it_IT&amp;locale=it_IT
                    </li>
                    <li>
                      Safari 8 Yosemite:
                      https://support.apple.com/kb/PH19214?viewlocale=it_IT&amp;locale=it_IT
                    </li>
                    <li>
                      Safari na iPhone, iPad lub iPod touch:
                      https://support.apple.com/it-it/HT201265
                    </li>
                  </ul>
                </li>
              </ol>
            </p>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default Cookies;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
